import React, { useState } from 'react';
import Text from '../components/Text';
import Button from '../components/Button';
import Input from '../components/Input';
import backgroundImage from '../images/login.jpg';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/Alerta';
import newLogo from '../images/Logo mirecibosdesueldo.png';  // Importa el nuevo logo
import { useAuth } from '../auth';  // Importa el hook de autenticación

function Login() {
  const [cedula, setCedula] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const navigate = useNavigate();
  const [inputBorderColor, setInputBorderColor] = useState('');
  const { login } = useAuth();  // Obtén la función de login del contexto

  const handleCedulaChange = (e) => {
    setCedula(e.target.value);
  };

  const handleContrasenaChange = (e) => {
    setContrasena(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('Iniciando sesión con:', { cedula, contrasena });

    if (cedula === '' || contrasena === '') {
      setAlertMessage('Faltan completar los campos');
      setAlertType('Atencion');
      setInputBorderColor('border-red-700');
      return;
    }

    try {
      const response = await axios.post('https://mirecibodesueldo.uy/api/login', { cedula, contrasena });
      console.log('Respuesta del backend:', response.data);

      if (response.status === 200) {
        console.log('Inicio de sesión exitoso');
        const { tipo, nombre, rol } = response.data;
        login(response.data);  // Establece el usuario autenticado en el contexto

        if (tipo === 'administrador') {
          if (rol === 'admin1') {
            navigate('/admin1');
          } else if (rol === 'admin2') {
            navigate('/admin2');
          } else if (rol === 'admin3') {
            navigate('/admin3');
          }
        } else if (tipo === 'empleado') {
          console.log(`Navegando a /empleado/${cedula}`);
          navigate(`/empleado/${cedula}`);
        }
      } else {
        console.error('Error en el inicio de sesión:', response.data);
        setInputBorderColor("border-red-700");
        setAlertMessage('Usuario o contraseña incorrectos');
        setAlertType('Autenticacion fallida');
        setContrasena('');
      }
    } catch (error) {
      console.error('Error de red:', error);
      setInputBorderColor('border-red-700');
      setAlertMessage('Usuario o contraseña incorrectos, por favor intenta nuevamente');
      setAlertType('Autenticacion fallida');
      setContrasena('');
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center p-4"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      >
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <div className="flex flex-col items-center text-center mb-4">
          <img src={newLogo} className="mx-auto" alt="Logo" />
          <Text size="xl" width="hug" className="text-blue-500 mt-4">Recibos de sueldos electrónicos</Text>
        </div>
        {alertMessage && <Alert message={alertMessage} type={alertType} />}
        <form className="flex flex-col gap-4 mt-6" onSubmit={handleLogin}>
          <div className="flex flex-col gap-2">
            <Text size="base" width="fixed" className="text-blue-500">Cédula</Text>
            <Input value={cedula} onChange={handleCedulaChange} placeholder="Rellene aquí" className={`w-full ${inputBorderColor}`} width="fill" minWidth="250px" />
          </div>
          <div className="flex flex-col gap-2">
            <Text size="base" width="fixed" className="text-blue-500">Contraseña</Text>
            <Input value={contrasena} onChange={handleContrasenaChange} type="password" placeholder="Rellene aquí" className={`w-full ${inputBorderColor}`} width="fill" minWidth="250px" />
          </div>
          <Button onClick={handleLogin} className="w-full mt-4" type="secondary" width="fill" minWidth="250px">Iniciar Sesión</Button>
        </form>
        <div className="text-center mt-4">
          <a href="/forgot-password" className="text-blue-500 underline">¿Olvidaste tu contraseña?</a>
        </div>
      </div>
    </div>
  );
}

export default Login;

