import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../axiosConfig';
import Input from '../components/Input';
import Button from '../components/Button'; // Corrige la ruta del import
import ButtonMenu from '../components/ButtonMenu';
import Text from '../components/Text'; // Corrige la ruta del import
import backgroundImage from '../images/login.jpg';
import Alert from '../components/Alerta';  // Importa el componente de alerta

function Empleado() {
  const { cedula } = useParams();
  const [latestRecibo, setLatestRecibo] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [vencimientoCarnetDeSalud, setVencimientoCarnetDeSalud] = useState('');
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showVencimientoForm, setShowVencimientoForm] = useState(false);
  const [showActualizarEmailForm, setShowActualizarEmailForm] = useState(false);
  const [showActualizarVencimientoForm, setShowActualizarVencimientoForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);  // Nuevo estado para el formulario de contraseña
  const [showEmailInfo, setShowEmailInfo] = useState(false); // Estado para mostrar info del email
  const [showVencimientoInfo, setShowVencimientoInfo] = useState(false); // Estado para mostrar info del vencimiento
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState(''); // Mensaje de alerta específico para el formulario de contraseña
  const [passwordAlertType, setPasswordAlertType] = useState(''); // Tipo de alerta específica para el formulario de contraseña
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para manejar el menú lateral
  const [alertVencimientoVisible, setAlertVencimientoVisible] = useState(false);

  const verificarVencimiento = useCallback(async () => {
    try {
      const response = await axios.get(`https://mirecibodesueldo.uy/api/empleado/${cedula}/vencimiento`);
      if (response.data === '') {
        setShowVencimientoForm(true);
      } else {
        setVencimientoCarnetDeSalud(response.data);
        const vencimientoDate = new Date(response.data);
        const today = new Date();
        const timeDifference = vencimientoDate - today;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        if (daysDifference <= 60) {
          setMessage('¡Atención! Su carnet de salud vence pronto.');
          setAlertType('Info');
        }
      }
    } catch (error) {
      console.error('Error verificando vencimiento:', error);
    }
  }, [cedula]);

  const verificarEmail = useCallback(async () => {
    try {
      const response = await axios.get(`https://mirecibodesueldo.uy/api/empleado/${cedula}/email`);
      if (response.data === 'Desconocido') {
        setShowEmailForm(true);
      } else {
        setEmail(response.data);
        verificarVencimiento();
      }
    } catch (error) {
      console.error('Error verificando email:', error);
    }
  }, [cedula, verificarVencimiento]);

  const getNombre = useCallback(async () => {
    try {
      const response = await axios.get(`https://mirecibodesueldo.uy/api/empleado/${cedula}/nombre`)
      console.log('ACCEDIO NOMBRE: ', response.data)
      setNombre(response.data)
    } catch (error) {
      console.error('Error accediendo al nombre', error)
    }
  }, [cedula]);

  const fetchLatestRecibo = useCallback(async () => {
    try {
      const response = await axios.get(`https://mirecibodesueldo.uy/api/recibos/latest/${cedula}`);
      setLatestRecibo(response.data);
    } catch (error) {
      console.error('Error fetching latest recibo:', error);
    }
  }, [cedula]);

  const fetchAvailableDates = useCallback(async () => {
    try {
      const response = await axios.get(`https://mirecibodesueldo.uy/api/recibos/dates/${cedula}`);
      setAvailableDates(response.data);
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  }, [cedula]);



  useEffect(() => {
    if (cedula) {
      verificarEmail();
      verificarVencimiento();
      fetchLatestRecibo();
      fetchAvailableDates();
      getNombre();
    }
  }, [cedula, verificarEmail, verificarVencimiento, fetchLatestRecibo, fetchAvailableDates, getNombre]); 



  const handleDateChange = async (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === '') return; // No hacer nada si se selecciona la opción "Seleccionar fecha"

    setSelectedDate(selectedValue);
    try {
      const response = await axios.get(`https://mirecibodesueldo.uy/api/recibos/${cedula}/${selectedValue}`);
      setLatestRecibo(response.data);
    } catch (error) {
      console.error('Error fetching recibo for selected date:', error);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Falta completar el campo');
      setAlertType('Atención');
      return;
    }
    try {
      await axios.post('https://mirecibodesueldo.uy/api/update-email', null, {
        params: { cedula, email, emailConfirm: email }
      });
      if (showEmailForm) {
        setMessage('');
        setShowEmailForm(false);
        verificarVencimiento();
      } else if (showActualizarEmailForm){
        setMessage('Email actualizado con exito.')
        setAlertType('Completado')
      }
    } catch (error) {
      console.error('Error actualizando email:', error);
      setMessage('Error actualizando email');
      setAlertType('Error');
    }
  };

  const handleVencimientoSubmit = async (e) => {
    e.preventDefault();
    if (!vencimientoCarnetDeSalud) {
      setMessage('Falta completar el campo');
      setAlertType('Atención');
      return;
    }
    const fechaRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (!fechaRegex.test(vencimientoCarnetDeSalud)) {
      setMessage('Formato de fecha inválido, debe ser DD-MM-AAAA');
      setAlertType('Error');
      return;
    }
    try {
      console.log('ESTOY EN EL TRY')
      await axios.post('https://mirecibodesueldo.uy/api/update-vencimiento', null, {
        params: { cedula, vencimiento: vencimientoCarnetDeSalud }
      });

      console.log('PASE EL AXIOS.POST')
      
      if (showVencimientoForm) {
        console.log("ENTRE A VENCIMIENTO NORMAL")
        setMessage('');
        setShowVencimientoForm(false);
      } else if (showActualizarVencimientoForm){
        console.log('ENTRE ACTUALIZAR TRUE');
        setMessage('Fecha actualizada con exito.');
        setAlertType('Completado');
        verificarVencimiento();
      }

      console.log('NO ENTRE A NADA')
    } catch (error) {
      console.error('Error actualizando vencimiento:', error);
      setMessage('Error actualizando vencimiento');
      setAlertType('Error');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setPasswordMessage('Todos los campos son obligatorios');
      setPasswordAlertType('Atención');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setPasswordMessage('Las contraseñas nuevas no coinciden');
      setPasswordAlertType('Error');
      return;
    }
    try {
      await axios.post('https://mirecibodesueldo.uy/api/change-password', null, {
        params: { cedula, currentPassword, newPassword, confirmNewPassword }
      });
      setPasswordMessage('Contraseña actualizada con éxito');
      setPasswordAlertType('Completado');
      setShowPasswordForm(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error('Error cambiando la contraseña:', error);
      setPasswordMessage('Error cambiando la contraseña');
      setPasswordAlertType('Error');
    }
  };

  const resetAlert = () => {
    setMessage('');
    setAlertType('');
  };

  const resetPasswordAlert = () => {
    setPasswordMessage('');
    setPasswordAlertType('');
  };

  const maskEmail = (email) => {
    const [name, domain] = email.split('@');
    const maskedName = name.slice(0, Math.ceil(name.length / 2)) + '*'.repeat(Math.floor(name.length / 2));
    return `${maskedName}@${domain}`;
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Ajustar la fecha al día correcto
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="flex min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={`transition-transform duration-300 ${isMenuOpen ? 'absolute inset-y-0 left-0 w-64' : 'w-16'} bg-gray-200`}>
        <Button
          className="p-2 m-2 bg-black hover:bg-gray-600 rounded"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          ☰
        </Button>
        {isMenuOpen && (
          <div className="p-4">
            <div className="flex flex-col items-center text-center mb-4">
              <Text size="xl" width="fixed" className="text-black text-center font-bold">Hola {nombre}!</Text>
            </div>

            <div>
              <ButtonMenu 
                type="menu" 
                className="block mt-2" 
                onClick={() => {
                  setShowVencimientoInfo(true);
                  setShowEmailInfo(false);
                  setShowPasswordForm(false);
                  setShowActualizarVencimientoForm(false);
                  setShowActualizarEmailForm(false);
                  resetAlert();
                }}
              >
                Vencimiento carnet de salud
              </ButtonMenu>

              <ButtonMenu 
                type="menu" 
                className="block mt-2" 
                onClick={() => {
                  setShowEmailInfo(true);
                  setShowVencimientoInfo(false);
                  setShowPasswordForm(false);
                  setShowActualizarVencimientoForm(false);
                  setShowActualizarEmailForm(false);
                  resetAlert();
                }}
              >
                Email registrado
              </ButtonMenu>

              <ButtonMenu
                className="block mt-2" 
                onClick={() => {
                  setShowActualizarVencimientoForm(true);
                  setShowActualizarEmailForm(false);
                  setShowVencimientoForm(false);
                  setShowEmailInfo(false);
                  setShowPasswordForm(false);
                  resetAlert();
                }}
              >
                Actualizar vencimiento carnet
              </ButtonMenu>

              <ButtonMenu
                className="block mt-2" 
                onClick={() => {
                  setShowActualizarEmailForm(true);
                  setShowActualizarVencimientoForm(false);
                  setShowEmailForm(false);
                  setShowVencimientoInfo(false);
                  setShowPasswordForm(false);
                  resetAlert();
                }}
              >
                Actualizar email
              </ButtonMenu>

              <ButtonMenu
                className="block mt-2" 
                onClick={() => {
                  setShowPasswordForm(true);
                  setShowEmailInfo(false);
                  setShowVencimientoInfo(false);
                  setShowActualizarVencimientoForm(false);
                  setShowActualizarEmailForm(false);
                  resetAlert();
                }}
              >
                Cambiar contraseña
              </ButtonMenu>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col items-center justify-center flex-1 p-4">
        {showPasswordForm ? (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl relative">
            {passwordMessage && (
              <div className="mt-4">
                <Alert message={passwordMessage} type={passwordAlertType} />
              </div>
            )}
            <button 
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" 
              onClick={() => {
                setShowPasswordForm(false);
                resetPasswordAlert();
              }}
            >
              &times;
            </button>
            <form className="flex flex-col gap-4 mt-6" onSubmit={handlePasswordSubmit}>
              <Text size="xl" width="fixed" className="text-turquoise-500 text-center">Cambio de contraseña</Text>
              <div className="flex flex-col gap-2">
                <Text size="base" width="fixed" className="text-turquoise-500">Contraseña actual</Text>
                <Input
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="Ingrese aqui su contraseña actual"
                  className="w-full"
                  type="password"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Text size="base" width="fixed" className="text-turquoise-500">Nueva contraseña</Text>
                <Input
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Ingrese su nueva contraseña"
                  className="w-full"
                  type="password"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Text size="base" width="fixed" className="text-turquoise-500">Confirme su nueva contraseña</Text>
                <Input
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  placeholder="Confirme su nueva contraseña"
                  className="w-full"
                  type="password"
                />
              </div>
              <Button className="w-full mt-4" type="secondary" htmlType="submit">Actualizar contraseña</Button>
            </form>
          </div>
        ) : showEmailForm ? (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl">
            {message && (
              <div className="mt-4">
                <Alert message={message} type={alertType} />
              </div>
            )}
            <form className="flex flex-col gap-4 mt-6" onSubmit={handleEmailSubmit}>
              <Text size="xl" width="fixed" className="text-turquoise-500 text-center">Se requiere su dirección de correo electrónico para notificarle cuando se le suba un nuevo recibo de sueldo:</Text>
              <div className="flex flex-col gap-2">
                <Text size="base" width="fixed" className="text-turquoise-500">Correo electrónico</Text>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Ingrese aqui su email"
                  className="w-full"
                />
              </div>
              <Button className="w-full mt-4" type="secondary" htmlType="submit">Actualizar correo electrónico</Button>
            </form>
          </div>
        ) : showVencimientoForm ? (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl">
            {message && (
              <div className="mt-4">
                <Alert message={message} type={alertType} />
              </div>
            )}
            <form className="flex flex-col gap-4 mt-6" onSubmit={handleVencimientoSubmit}>
              <Text size="xl" width="fixed" className="text-turquoise-500 text-center">Se requiere la fecha de vencimiento del carnet de salud para poder notificarle días previos a su vencimiento:</Text>
              <div className="flex flex-col gap-2">
                <Text size="base" width="fixed" className="text-turquoise-500">Vencimiento carnet de salud</Text>
                <Input
                  type="text"
                  value={vencimientoCarnetDeSalud}
                  onChange={(e) => setVencimientoCarnetDeSalud(e.target.value)}
                  placeholder="DD-MM-AAAA"
                  className="w-full"
                />
              </div>
              <Button className="w-full mt-4" type="secondary" htmlType="submit">Actualizar Fecha de Vencimiento</Button>
            </form>
          </div>
        ) : showActualizarVencimientoForm ? (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl relative">
            {message && (
              <div className="mt-4">
                <Alert message={message} type={alertType} />
              </div>
            )}
            <form className="flex flex-col gap-4 mt-6" onSubmit={handleVencimientoSubmit}>
                <button 
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" 
                  onClick={() => {
                    setShowActualizarVencimientoForm(false)
                    resetAlert();
                  }}
                >
                  &times;
                </button>
              <Text size="xl" width="fixed" className="text-turquoise-500 text-center">Ingrese la fecha de vencimiento de su carnet de salud actual:</Text>
              <div className="flex flex-col gap-2">
                <Text size="base" width="fixed" className="text-turquoise-500">Vencimiento carnet de salud</Text>
                <Input
                  type="text"
                  value={vencimientoCarnetDeSalud}
                  onChange={(e) => setVencimientoCarnetDeSalud(e.target.value)}
                  placeholder="DD-MM-AAAA"
                  className="w-full"
                />
              </div>
              <Button className="w-full mt-4" type="secondary" htmlType="submit">Actualizar Fecha de Vencimiento</Button>
            </form>
          </div>
        ) : showActualizarEmailForm ? (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl relative">
            {message && (
              <div className="mt-4">
                <Alert message={message} type={alertType} />
              </div>
            )}
            <form className="flex flex-col gap-4 mt-6" onSubmit={handleEmailSubmit}>
                <button 
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" 
                  onClick={() => {
                    setShowActualizarEmailForm(false);
                    resetAlert();
                  }}
                >
                  &times;
                </button>
              <Text size="xl" width="fixed" className="text-turquoise-500 text-center">Modifique su email al que le llegaran las notificaciones de que se le ha cargado un nuevo recibo de sueldo:</Text>
              <div className="flex flex-col gap-2">
                <Text size="base" width="fixed" className="text-turquoise-500">Correo electrónico</Text>
                <Input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Ingrese aqui su email"
                  className="w-full"
                />
              </div>
              <Button className="w-full mt-4" type="secondary" htmlType="submit">Actualizar Fecha de Vencimiento</Button>
            </form>
          </div>
        ) : showEmailInfo ? (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl relative">
            <button 
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" 
              onClick={() => {
                setShowEmailInfo(false);
              }}
            >
              &times;
            </button>
            <div className="flex flex-col gap-4 mt-6">
              <Text size="xl" width="fixed" className="text-turquoise-500 text-center">Email registrado</Text>
              <Text size="base" width="fixed" className="text-turquoise-500 text-center">{maskEmail(email)}</Text>
            </div>
          </div>
        ) : showVencimientoInfo ? (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl relative">
            <button 
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" 
              onClick={() => {
                setShowVencimientoInfo(false);
              }}
            >
              &times;
            </button>
            <div className="flex flex-col gap-4 mt-6">
              <Text size="xl" width="fixed" className="text-turquoise-500 text-center">Vencimiento carnet de salud</Text>
              <Text size="base" width="fixed" className="text-turquoise-500 text-center">{formatDate(vencimientoCarnetDeSalud)}</Text>
            </div>
          </div>
        ) : (
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl">
            {latestRecibo ? (
              <div>
                {message && (
                <div className="mt-1 mb-4 relative">
                  <Alert message={message} type={alertType} />
                  <button 
                    className="absolute top-1 right-1 text-gray-500 hover:text-gray-800" 
                    onClick={resetAlert}
                  >
                    &times;
                  </button>
                </div>
                )}
                <Text size="xl" className="mb-4 text-center font-bold">Recibo de sueldo {latestRecibo.fecha}</Text>
                <a 
                  href={`https://mirecibodesueldo.uy/api/descargarRecibo/${latestRecibo.id}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 hover:underline text-center block"
                >
                  Recibo {nombre}-{latestRecibo.fecha}
                </a>
              </div>
            ) : (
              <Text size="xl" className="mb-4 text-center">No hay recibos disponibles</Text>
            )}
            <div className="mt-4">
              <Text size="lg" className="mb-2">Recibo un mes anterior:</Text>
              <select value={selectedDate} onChange={handleDateChange} className="w-full p-2 border rounded max-w-xl">
                <option value="" disabled>Seleccione una fecha</option>
                {availableDates.map((date, index) => (
                  <option key={index} value={date}>
                    {date}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Empleado;







