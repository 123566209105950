import React, { useState } from 'react';
import axios from '../axiosConfig';
import Input from '../components/Input';
import Button from '../components/Button';
import backgroundImage from '../images/login.jpg';
import Text from '../components/Text';
import Alert from '../components/Alerta';  // Importa el componente de alerta

function Admin1() {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState('');  // Estado para el tipo de alerta

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const fileObjects = selectedFiles.map(file => ({
      file,
      url: URL.createObjectURL(file)
    }));
    setFiles(fileObjects);
    setMessage(''); // Limpiar el mensaje cuando se seleccionan nuevos archivos
    setAlertType('');
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      setMessage('No se ha seleccionado ningún archivo');
      setAlertType('Atencion');
      return;
    }

    const formData = new FormData();
    files.forEach(fileObj => {
      formData.append('files', fileObj.file);
    });

    // Obtener el rol desde la URL
    const rolAdministrador = window.location.pathname.split('/')[1];
    formData.append('rolAdministrador', rolAdministrador);

    try {
      const response = await axios.post('https://mirecibodesueldo.uy/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Los archivos seleccionados se han subido con éxito');
      setAlertType('Completado');  // Tipo de alerta éxito
      setFiles([]); // Limpiar los archivos seleccionados
    } catch (error) {
      setMessage('Ha ocurrido un error en la subida de los archivos');
      setAlertType('Error');  // Tipo de alerta error
    }
  };

  const handleRemoveFile = (index, e) => {
    e.preventDefault(); // Evitar el envío del formulario
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center p-4"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="mb-5 bg-gray-200 p-3 rounded-lg shadow-lg w-full max-w-2xl">
        <Text size="3xl" weight="bold" width="fill" className="mb-1 mt-3 text-center text-turquoise-500">Subir Comunicados PDF</Text>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl">
        <form onSubmit={handleUpload} className="flex flex-col gap-4">
          <div className="relative flex justify-center">
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="absolute inset-0 opacity-0 cursor-pointer"
              style={{ width: '100%', height: '100%' }}
            />
            <Button type="outline" className="w-56 h-12">
              Elegir comunicados
            </Button>
          </div>
          {files.length > 0 && (
            <div className="mt-4 p-4 bg-gray-100 rounded max-h-76 overflow-y-auto">
              <Text size="xl" className="text-black text-center mb-3">Comunicados seleccionados:</Text>
              <ul>
                {files.map(({ file, url }, index) => (
                  <li key={index} className="flex items-center justify-between">
                    <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                      {file.name}
                    </a>
                    <button
                      onClick={(e) => handleRemoveFile(index, e)}
                      className="text-red-500 hover:text-red-700"
                    >
                      &times;
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex justify-center">
            <Button type="secondary" className="w-56 mt-4">
              Subir comunicados
            </Button>
          </div>
        </form>
        {message && (
          <div className="mt-4">
            <Alert message={message} type={alertType} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin1;





