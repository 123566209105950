import React, { useState } from 'react';
import axios from '../axiosConfig';
import Input from '../components/Input';
import Button from '../components/Button';
import Text from '../components/Text';
import backgroundImage from '../images/login.jpg';
import Alert from '../components/Alerta';  // Importa el componente de alerta

function ForgotPassword() {
    const [cedula, setCedula] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');  // Estado para el tipo de alerta

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!cedula || !email) {
            setMessage('Falta completar algún campo');
            setAlertType('Atencion');
            return;
        }
        try {
            console.log('Restableciendo con:', { cedula, email });
            const response = await axios.post('https://mirecibodesueldo.uy/api/reset-password', null, {
                params: { cedula, email }
            });
            setMessage('Correo de restablecimiento enviado con éxito');
            setAlertType('Completado');
        } catch (error) {
            console.error('Error al restablecer la contraseña:', error);
            setMessage('Error al restablecer la contraseña');
            setAlertType('Error');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-cover bg-center p-4" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="bg-gray-200 p-8 rounded-lg shadow-lg max-w-md w-full">
                <div className="flex flex-col items-center text-center">
                    <Text size="3xl" weight="bold" width="hug" className="text-turquoise-500 mt-4">Restablecer Contraseña</Text>
                </div>
                <form className="flex flex-col gap-4 mt-6" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <Text size="base" width="fixed" className="text-black">Cédula</Text>
                        <Input value={cedula} onChange={(e) => setCedula(e.target.value)} placeholder="Ingrese su cédula" className="w-full" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Text size="base" width="fixed" className="text-black">Correo electrónico</Text>
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Ingrese su dirección de correo electrónico" className="w-full" />
                    </div>
                    <Button className="w-full mt-4" type="secondary" htmlType="submit">Restablecer Contraseña</Button>
                </form>
                {message && (
                    <div className="mt-4">
                        <Alert message={message} type={alertType} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ForgotPassword;

