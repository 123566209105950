import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './auth';

const PrivateRoute = ({ element: Component, roles, ...rest }) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/login" />;
    }

    if (roles && user.tipo === 'administrador' && !roles.includes(user.rol)) {
        return <Navigate to="/login" />;
    }

    if (roles && user.tipo === 'empleado' && !roles.includes('EMPLOYEE')) {
        return <Navigate to="/login" />;
    }

    return <Component {...rest} />;
};

export default PrivateRoute;