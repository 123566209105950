import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Admin1 from './pages/Admin1';
import Admin2 from './pages/Admin2';
import Admin3 from './pages/Admin3';
import Empleado from './pages/Empleado';
import ForgotPassword from './pages/ForgotPassword';
import BorradoRecibos from './pages/BorradoRecibos';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './auth';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/" element={<Login />} /> {/* Ruta por defecto */}
                    
                    <Route 
                        path="/admin1" 
                        element={<PrivateRoute element={Admin1} roles={['admin1']} />} 
                    />
                    <Route 
                        path="/admin2" 
                        element={<PrivateRoute element={Admin2} roles={['admin2']} />} 
                    />
                    <Route 
                        path="/admin3" 
                        element={<PrivateRoute element={Admin3} roles={['admin3']} />} 
                    />
                    <Route 
                        path="/empleado/:cedula" 
                        element={<PrivateRoute element={Empleado} roles={['EMPLOYEE']} />} 
                    />
                    <Route 
                        path="/borrado-recibos" 
                        element={<PrivateRoute element={BorradoRecibos} roles={['admin3']} />} 
                    />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
